/* to convert dropdown button to cross button */

/* Hide the default arrow of the select */
.dropdown-default {
    background: url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
  }
  
  /* Hide the default arrow when an option is selected */
  .dropdown-with-close {
    background: none;
  }
  
  /* Style the close icon */
  /* .clear-icon-company {
    position: absolute;
    
    right: 80px;
    top: 50%;
    transform: translateY(-260%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  } */
  .clear-icon-OtherEnq-company
  {
    position: absolute;
    
    right: 80px;
    top: 50%;
    transform: translateY(-340%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .clear-icon-OtherEnq-project {
    position: absolute;
    
    right: 80px;
    top: 50%;
    transform: translateY(-20%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .clear-icon-Crep-other
  {
    position: absolute;
    
    right: 80px;
    top: 50%;
    transform: translateY(-180%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .clear-icon:hover {
    color: #2d32aa;
  }
  .no-ligatures {
    font-variant-ligatures: no-common-ligatures;
  }