
  
  .nounderline {
    text-decoration: none !important;
  }
   
   .accordion-button {
      pointer-events: none;
      
     
  }
  .buttonMargin::after
  {
    margin-left: -2px !important;
  }
  .sandboxAccordButton::after
  {
    margin-left: 89px !important;
  }

  .noProjectsMsg{
    

    letter-spacing: 0px;
    /* margin: 18px 98px 0 0; */
    display: inline-block;
    color: #2d32aa;
    font-family: "UniNeueRegular", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 15px
   

}
  

  .accordion{
    
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-btn-color: #2d32aa !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232d32aa'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%232d32aa' stroke-width='1'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232d32aa'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%232d32aa' stroke-width='1'/%3e%3c/svg%3e");
  }
 
  
  .accordion-button::after {
     
      pointer-events: all;
  }
  

  

  
  .tabular-button {
      padding: 0px;
      background-color:white; 
      color: rgba(112, 105, 105, 0.185);
      border-bottom: 4px solid rgba(112, 105, 105, 0.185);
      border-radius:1px;
      
      }
      .ntabbtn-right{
      background-color: gainsboro;
      color:gainsboro;
      }
      
      
      .nav-pills .nav-link.active
      {
      color: #2d32aa; 
      background-color:white; 
      
      }
     .activeNav{
      color: #2d32aa; 
      background-color:white; 
      border-bottom: 4px solid #2d32aa;
      border-radius:1px;
      }
      
  
  
  
  .text-center {
      text-align: center!important;
      display: grid;
      align-items: center;
  }
  
 
  .main-company-content
  {
    width:90% !important; 
    margin-left: 4.5% !important;
    margin-right: 2% !important;
    padding: 15px 12px 15px 15px !important;
  }

  .accordion-button:focus {
     border-color: none;
      
  }
  
 
  .accordion-button:not(.collapsed) {
     
          background-color: white;
          --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232d32aa'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%232d32aa' stroke-width='0.5'/%3e%3c/svg%3e");
    }
  
    .Test {
        --bs-bg-opacity: 1;
        border-left: 8px solid #ed8b00;
        height: 48px;
        width: 8px;
    }
  .Production {
      --bs-bg-opacity: 1;
      border-left: 8px solid #00bef0;
      height: 48px;
      width: 8px;
  }
.Sandbox{
    --bs-bg-opacity: 1;
      border-left: 8px solid gainsboro;
      height: 48px;
      width: 8px;
}

  .apim-project-row {
    display: block;
    background-color: #c9e9fb;
    margin: 2px 0;
    text-decoration: none;
    color: #005776;
    position: relative;
    display: flex;
    align-items: center;
}

.head-sub-title {
    width: 60%;
    display: inline-block;
    padding: 12px 15.99996px;
    color: #2d32aa;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.apim-project-link {
    text-decoration: solid;
    color: #2d32aa;
    padding: 12px 15.99996px;
}

.tb{
    letter-spacing: .5px;
    font-size: 16px;
    line-height: 8px;
    margin: 10px 0 0 0;
    padding: 0;
    display: inline-block;
    justify-content: space-around;
    color: #dee2e6;
    background-color: white;
}
