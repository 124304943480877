.after-sel-sec {
    margin: auto;
    width: 600px;
}
.head-titl-unset {
    align-items: unset;
    max-width: 64%;
    max-height: 37%;
}

.apim-pop-button-w {
    display: inline-block;
    text-align: right;
    width: 100%;
}

.ser-req-btn {
    width: 600px;
    margin: 40px auto 0 auto;
    display: block;
}

.btn-disable {
    background: #c9cac8;
    border: 1px solid #c9cac8;
    font-weight: normal;
    cursor: not-allowed;
}

.dropdown-w-info {
    display: flex;
}
.width-full {
    width: 100%;
}

.required-field-op {
    float: right;
    font-size: 11px;
    line-height: 13px;
    color: #a2a2a2;
    margin: 7px 0px 0px;
    padding: 0px;
}
.info-icon-w {
    text-decoration: none;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ccc;
    border-radius: 9px;
}

.floating-label {
    top: -20px;
    bottom: 10px;
    left: 0;
    font-size: 11px;
    opacity: 1;
    line-height: 19px;
    color: #909090;
    font-weight: normal;
    
}
.custom-input-wrapp {
    position: relative;
}
.input-info-label {
    color: #8b8b8b;
    font-size: 16px;
}
.countryCode-label
{
    color: #524d4d;
    font-size: 12px;
}
.inputText {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 20px;
    line-height: 20px;
    border-bottom: none;
    width: 100%;
    padding: 0;
}
.inputText.form-floating{
    border-bottom: none;
}

.dotted {border: 2px dotted black;}
.solid {border: 2px solid black; position: absolute;}
 .box{
        width: 20%;
        height: 12%;            
        position: absolute;
        top: 17%;
        left: 12%;
        background:white;
        
    }
  
.sec {
    border: 1px dashed #ccc;
    border-radius: 10px;
    position: relative;
    min-height: 110px;
    margin: 62px 0 0 0;
    padding: 26px 24px 29px 25px;
}
.head-titlw-w {
    font-weight: normal;
    /* font-weight: bold; */
    letter-spacing: .3px;
    position: absolute;
    top: -17px;
    left: 4px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 100px;
    font-size: 19px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
}
.sec-title {
    margin: 0;
    color: #2d32aa;
    
}
.nc-rep-contact-num-wrap{
    color: black;
    
    margin: 15px 0 15px 0;
}
.sec-inner-wrap {
    margin: 16px 0 0 0;
}
.serv-req {
    width: 550px;
    margin: auto;
}



.hide-content {
    display: none !important;
}
.wrapper span {
    padding-bottom: 10px;
}

.right-actions {
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
}
.span {
    padding-bottom: 10px;
}
ul{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.cust-text-a {
    width: 100%;
    border: 1px solid #0280a7;
    max-height: 93px;
    min-height: 93px;
    padding: 18px 95px 18px 20px;
    color: #2d32aa;
    margin: 0;
}
#textAreaModifyDescription::placeholder {
    /* change color of the placeholder in textarea */
    color: #2d32aa;
    opacity: 1;
}
.custom-loader {
    position: relative;
    line-height: 1;
    padding: 13.5px 24px;
}
.iconLoader {
    position: relative;
    top: 2px;
}

.form-select {
    cursor: pointer;
    border-style: solid;
}
.select2-selection {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    font-family: UniNeueRegular, sans-serif !important;
    font-size: 15px !important;
    line-height: 15px !important;
    
}
.react-tel-input .country-list .search-box {
    border: 1px solid #cacaca;
    border-radius: 3px;
    font-size: 13px;
    line-height: 13px;
    margin-left: 6px;
    padding: 3px 8px 5px;
    outline: none;
}


.react-tel-input .country-list .country {
    padding: 4px 6px;
    font-family: UniNeueRegular, sans-serif !important;
    font-size: 13px !important;
    line-height: 13px !important;
}
#btnSubmitRegisterCompany:disabled
{
    background: #c9cac8;
    border: 1px solid #c9cac8;
    color:white;
    font-weight: normal;
    cursor: not-allowed;
}
.react-tel-input .selected-flag::before {
    content: 'Select country code'; /* Placeholder text */
    font-size: 12px;
    color: #888;
    margin-right: -8px;
    position: absolute;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
.phone-input-wrapper.with-placeholder .react-tel-input .selected-flag::before {
    content: ''; /* Remove placeholder text */
  }
  .flag-dropdown {
    height: 36px;
    width: 138px;
}

/* Ensure the flag image is visible when a flag is selected */
.react-tel-input .selected-flag img {
    visibility: visible;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #f5f5f5;
}

.react-tel-input .dropdown-item {
    color: #2d32aa !important; /* Ensure country name text is visible */
}

