
.Modal-header .Modal-title {
    font-size: 20px;
    line-height: 24px;
    background: rgb(45, 50, 170);
    /* text-transform: capitalize; */
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 13px 20px;
}
.apim-pop-header {
    font-size: 20px;
    line-height: 24px;
    background: #2d32aa;
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    padding: 13px 20px 13px 20px;
}
.pop-close-wrap {
    float: right;
    font-size: 16px;
    margin: 0;
    padding: 4px 0 4px 5px;
    color: #fff;
    text-decoration: none;
}
 /* .btn-close{
    float: right;
    font-size: 16px;
    margin: 0;
    padding: 4px 0 4px 5px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: none;
} */
.floating-label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 1px;
    transition: .2s ease all;
    font-size: 20px;
    line-height: 24px;
}
.floating-label .custom-input-wrapp{
    color: #2d32aa;
    font-weight: bold;
}
.wrapper span {
    padding-bottom: 10px;
}
.apim-pop-content .custom-input-wrapp {
    position: relative;
}
.modal-content{
height: 345px;
}
.form-error {
    color: red;
    margin: 6.9px 0;
    visibility: hidden;
    padding: 0;
    clear: both;
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
}
#createButton {
    background: #c9cac8;
    border: 1px solid #c9cac8;
    font-weight: normal;
    cursor: not-allowed;
}

.custom-input-wrapp
{
    height: fit-content;
}
.input-group{
    margin: 54px 0 53px 0;
}


.modal{
    display: block !important; 
}

/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    height: 80vh;
    overflow-y: auto;
}