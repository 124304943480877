.wrapper div {
    font-family: "UniNeueRegular", sans-serif;
    font-size: 15.1px;
}
/* .activity-dd-wrapper
{
    
    display: inline;
} */
.activity-log-list-w .filter-company-w .activity-dd-wrapper {
    width: 300px !important;
}
.activity-log-list-w 
{
    margin-top: 4%;
}


/* to set borders of all dropdowns in header and calender */
#activityProjectDD,#activityCompanyDD,#activityScopeDD{
    border: none;
    border-bottom: 1px solid #000;
}
.date-text-center{
    border: none;
    border-bottom:1px solid #000 !important;
    margin-top: 7.5px;
}
#activityLogContainer
{
    background-color: #fff;
}
.empty-cont-header {
    border-bottom: 1px solid #000 !important;
}
.bold-label{
    font-weight: 550 !important;
}
.filter-w .form-f-w {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0 25px 0 0;
}
.activity-log-list-w .filter-w {
    margin: 0 0 20px 0;
}
.activity-log-list-w .filter-company-w {
    justify-content: center;
}
.filter-w {
    margin: 0 0 40px 0;
    display: flex;
}
.activity-log-list-w .form-f-w.list-table-btn {
    align-items: center;
    
}
.filter-w .form-f-w.list-table-btn {
    flex-direction: row;
    margin: 0;
    padding: 0;
    align-items: flex-end;
}
.react-datepicker {
    font-family:  "UniNeueRegular", sans-serif;
    font-size: .8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    display: inline-block;
    position: relative;
    line-height: initial;
     /* width: 20%;   */
    
}

.react-datepicker-popper {
  
    width: 20% !important;
}


#resetActivityFilters,#submitActivityLogFilter{
    max-width: 35px;
    text-align: center;
    border: 1px solid #6e9fbc;
    color: #2d32aa;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 7px 16px;
    border-radius: 5px;
    max-height: 35px;
    margin-right: 15px;
    margin-bottom: 25px;
    font-weight: 600 !important;
}

#resetActivityFilters[disabled],#submitActivityLogFilter[disabled]
{
    background: #ccc;
    cursor: not-allowed;
    color: #4a4a4a !important;
    pointer-events: none;
}
#input[disabled]  {
    cursor: not-allowed;
    pointer-events: none;

    color: #b1b1b1;
    border-color: #e1e1e1;
    background-color: #fdfdfd;
}
.form-select:disabled {
    cursor: not-allowed;
    pointer-events: none;

    color: #b1b1b1;
    border-color: #e1e1e1;
    background-color: #fdfdfd;
}

#resetActivityFilters:hover,#submitActivityLogFilter:hover {
    background-color: #0072c6 !important;
    color: #fff;
    text-decoration: none;
}
.SFSqW div:first-child {
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
}
.custom-popover {
    height: 5px !important;
    max-width: 80px;
    /* Other styles */
  }
  abbr[title] {
    -webkit-text-decoration: none !important;
    text-decoration: none !important;
    cursor: default !important;
    -webkit-text-decoration-skip-ink: none !important;
    text-decoration-skip-ink: none !important;
}

.calendarInput{
    border: none;
    border-bottom:1px solid #000 !important;
    margin-top: 7.5px;
}

.react-calendar {
    width:120% !important;
    max-width: none !important;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}
.react-calendar__navigation button {
   color: #2d32aa;
   
}
.react-calendar__navigation__label__labelText
{
    color: #000;
}
.calendar-wrapper {
    position: relative;
  }
  
  .custom-calendar {
    position: absolute;
    top: 100%; 
    left: 0; 
    z-index: 999;
  
  }


  .activityFilterButton {
	    position: relative;
		padding: .58928571em .58928571em .58928571em;
		font-size: .85714286rem;
		text-transform: none;
		
		border: none;
    vertical-align: baseline;
    background: #e0e1e2 none;
    color: rgba(0, 0, 0, .6);
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 .25em 0 0;
		padding: .58928571em .58928571em .58928571em;
    text-shadow: none;
    font-weight: 700;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: .28571429rem;
	}
    .activityFilterButton:hover {background-color: #ababab}
    .activityFilterButton:disabled{
        cursor: not-allowed;
   pointer-events: none;
        background-color: #e0e1e2 none;
        color: #999999;
      }