
/* .Modal-header .Modal-title {
    font-size: 20px;
    line-height: 24px;
    background: rgb(45, 50, 170);
    
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 13px 20px;
} */
/* .modalBackgroundForAddProject {
    height: 500px;
    overflow-y: auto;
} */
/* .modal-content{
    height: 500px;
    } */
.apim-pop-header {
    font-size: 20px;
    line-height: 24px;
    background: #2d32aa;
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    padding: 13px 20px 13px 20px;
}
.pop-close-wrap {
    float: right;
    font-size: 16px;
    margin: 0;
    padding: 4px 0 4px 5px;
    color: #fff;
    text-decoration: none;
}
 .btn-close{
    float: right;
    font-size: 16px;
    margin: 0;
    padding: 4px 0 4px 5px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: none;
    
}
.floating-label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 1px;
    transition: .2s ease all;
    font-size: 20px;
    line-height: 24px;
}
.pop-close-wrap {
    float: right;
    font-size: 16px;
    margin: 0;
    padding: 4px 0 4px 5px;
    color: white;
    text-decoration: none;
  }
/* .rightSec-head {
    float: right;

    .code-expire {
      font-size: 17px;
      margin: 0 11px 0 0;
      text-transform: none;

      span {
        min-width: 60px;
        display: inline-block;
        text-align: right;
        letter-spacing: 0;
        word-spacing: 0;
      }
    }
  } */
.floating-label .custom-input-wrapp{
    color: #2d32aa;
    font-weight: bold;
}
.wrapper span {
    padding-bottom: 10px;
}
.apim-pop-content .custom-input-wrapp {
    position: relative;
}

.form-error {
    color: red;
    margin: 6.9px 0;
    visibility: hidden;
    padding: 0;
    clear: both;
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
}
.comm-button-style.primary{
    background: #2d32aa;
    color: #fff;
}
.comm-button-style.secondary{
    color: #2d32aa;
    background: #fff;
}
comm-button-style {
    border-radius: 40px;
    background: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    min-width: 113px;
    padding: 13.5px 33px;
    border: 1px solid #2d32aa;
    color: #2d32aa;
    margin: 0 10px 0 0;
    transition: all .2s ease-in-out;
}
.comm-button-style.secondary:hover , .comm-button-style.primary:hover{
    background-color: #212861;
    color: white;
}
#createNewProjButton:disabled,#closeButton:disabled {
    background: #c9cac8;
    border: 1px solid #c9cac8;
    font-weight: normal;
    cursor: not-allowed;
    color: white;
}
.form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 24px;
    line-height: 19px;
    border-bottom: 1px solid #707070;
    width: 100%;
    color: #2d32aa;;
    padding: 7px 0 10px 0;
    font-weight: bold;
}
.custom-input-wrapp
{
    height: fit-content;
    margin-right:0px !important;
}
.input-group{
    margin: 54px 0 53px 0;
}
/* below css is to remove scrollbar from the modal body, refer this to change anything */
.modal-dialog-scrollable .modal-body {
    overflow-y:hidden;
}
