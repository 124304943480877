.serv-req .drop-d-w {
    display: flex;
    justify-content: center;
    align-items: center;
}
.serv-req,.after-sel-sec {
    width: 550px;
    margin: auto;
}
/* .serv-req-cre-main{
    background-color:#e7f1ff;
} */
.serv-req .info-icon-w {
    text-decoration: none;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ccc;
    border-radius: 9px;
}
.form-select {
    border-style: none;
    word-wrap: normal;
    cursor: pointer;
    display: block !important;

}
h2,.textdefault{
    color: #2d32aa;
}
select .dropdownIndicator{
    color: #2d32aa;
    fill: #2d32aa;;
}
.info-icon-w {
    text-decoration: none;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ccc;
    border-radius: 9px;

    .info-icon {
      padding: 0;
    }
  }
  a{
    cursor: pointer;
  }
  .icon-info-circle:before {
    content: "\e915";
    color: #005775;
    
  }
  .sec {
    border: 1px dashed #ccc;
    border-radius: 10px;
    position: relative;
    min-height: 100px;
    margin: 62px 0 0 0;
    padding: 26px 24px 0 25px;
}
.head-titlw-w {
  font-weight: bold;
  letter-spacing: .3px;
  position: absolute;
  top: -17px;
  left: 18px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 100px;
  font-size: 19px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}


*, ::after, ::before {
  box-sizing: border-box;
}

/* to convert dropdown button to cross button */

/* Hide the default arrow of the select */
.dropdown-default {
  background: url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
}

/* Hide the default arrow when an option is selected */
.dropdown-with-close {
  background: none;
}

/* Style the close icon */
.clear-icon-serviceRqst {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #2d32aa;
  font-size: 1.5rem;
  cursor: pointer;
}

.clear-icon-serviceRqst:hover {
  color: #2d32aa;
}



