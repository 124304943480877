html {
    background: #fafafa;
    width: 100%;
    height: 100%;
  }
  
  .App {
    font-family: "UniNeueRegular", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    line-height: 1.42857;
    text-rendering: optimizeLegibility;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .theadth {
    background: #2d32aa;
    color: #fff;
    padding: 14px ​12px;
    border-radius: 0;
    font-weight: normal;
    border-top: 1.5px solid #3e6a85;
    border-bottom: 1.5px solid #3e6a85;
    border-left: none;
    min-width: 120px;
}
.popover {
  max-width: 50%;
  min-height: 10%;
  font-size: large;
  border: 1px solid #a2a2a2;
  
}
div.gxarWd {
  flex: 1 0 auto;
  font-weight: 700;
}



.sc-cwHptR{
  margin-right: 30%;
}


