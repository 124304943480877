.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.dropdown-menu li a {
  color: black !important;
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid #2d32aa;
  width: 100%;
  display: inline-block;
  margin: 0;
  transition: all 0.1s ease-in;
  padding: 18px 20px; }

  .dropdown-menu li a:hover {
    text-decoration: none !important;
    background: #2d32aa;
    color: #fff !important; }
  .dropdown-menu li a.disabled-anchor {
    background: #ccc;
    cursor: not-allowed;
    color: #4a4a4a !important; }
.dropdown-menu li:last-child a {
  border-bottom: none; }

  .dropdown-menu {
    position: absolute;
    background: #fff;
    z-index: 1;
    list-style-type: none;
    margin: 0;
    padding: 0;
    right: 13px;
    top: 85px;
    border: 1px solid #2d32aa;
    min-width: 235px;
    z-index: 12; }
  

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer {

  left: 0;
  bottom: 0;
  width: 100%;
 
 
}
