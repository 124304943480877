.comm-button-style.primary{
    background: #2d32aa;
    color: #fff;
}
.comm-button-style.secondary{
    color: #2d32aa;
    background: #fff;
}
.comm-button-style-clear,.comm-button-style-close,.comm-button-style-confirm{
    border-radius: 40px;
    background: #fff;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 17px;
    min-width: 105px;
    padding: 12px 24px;
    border: 1px solid #2d32aa;
    color: #2d32aa;
    margin: 0 10px 0 0;
    transition: all .2s ease-in-out;
}
.comm-button-style.secondary:hover , .comm-button-style.primary:hover, .comm-button-style-close:hover,.highlight:hover{
    background-color: #212861;
    color: white;
}
.modal-70w
{
   max-width: 60% !important;
   
  
}
.highlight {
    background: #2d32aa;
    color: #fff;
  }
 
.footer-container {
    display: flex;
    align-items: center; /* Center the buttons vertically if needed */
  }
  
  .footer-container .comm-button-style-confirm {
    margin-left: auto;
  }
.modal-content
{
    height:264px !important;
}
.modal-footer{
   
    margin-top: 0 !important;
}
.otpContainer{
    margin-left: 120px;
}
.otp-field{
   text-align: center;
   /* border-top: 0;
   border-left: 0;
   border-right: 0; */
   
}
.OtpBody{
overflow-x: hidden;
}
.popinfo{
    color:#2d32aa ;
}
