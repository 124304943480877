.react-tel-input .selected-flag {
  position: relative;
  display: flex;
  align-items: center;
}

.react-tel-input .form-control {
  width: 100%;
  padding-left: 153px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  color: #2d32aa;
}

/* Placeholder text when no country is selected */
.react-tel-input .selected-flag::before {
  content: 'Select country code'; /* Placeholder text */
  font-size: 12px;
  color: #888;
  margin-right: -8px;
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: #f5f5f5;
}
.flag-dropdown {
  height: 36px;
  width: 138px;
}

.phone-input-wrapper.with-placeholder .react-tel-input .selected-flag::before {
  content: ''; /* Remove placeholder text */
}

/* Ensure the flag image is visible when a flag is selected */
.react-tel-input .selected-flag img {
  visibility: visible;
}

.react-tel-input .dropdown-item {
  color: #2d32aa !important; /* Ensure country name text is visible */
}
.react-tel-input .country-list .country-name {
  color: #2d32aa;
  margin-right: 6px;
}



/* Dropdown arrow style */
.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: -2px;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
  margin-left: 93px;
}
