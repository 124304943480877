.editCompany{
    font-family: "UniNeueRegular", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
}

.page-title {
    display: inline-block;
    margin: 0;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
}
.com-custom-input-wrapp {
    color: #2d32aa;
    font-weight: bold;
    position: relative;
}
.com-cust-text-a {
    width: 100%;
    border: 1.5px solid #0d6efd;
    max-height: 93px;
    min-height: 93px;
    padding: 18px 95px 18px 20px;
    margin: 0;
    color: #2d32aa;
}
.com-right-actions {
    right: 8px;
    top: 8px;
    width: auto;
    height: auto;
    position: absolute;
}
.accordion {
    --bs-accordion-border-width: none;
}
.crep-wrap .sel-othertxt {
    line-height: 23px;
    color: black;
}
#search-select-representative{
    border-left: none;
    border-right: none;
    border-top: none;
    padding-left: 18px;
    border-bottom: 1.5px solid #2d32aa !important;
    color: #2d32aa;
    font-family:"UniNeueRegular", sans-serif;
}
.custom-sel-label {
    position: relative;
    margin: 0 20px 0 0;
    width: 30%;
    height: 40%;

    .aftersel-txt {
      position: absolute;
      left: 21px;
      top: -22px;
      margin-bottom: 2px;
      z-index: 1;

      &.display-none {
        display: none;
      }

      &.display-block {
        display: block;
      }
    }
  }
  .crep-wrap .custom-sel-label .aftersel-txt {
    position: absolute;
    font-size: 13px;
    font-weight: 200;
    margin-left: 0px;
    
    color: black;
    left: 21px;
    top: -18px;
    z-index: 1;
}
.crep-wrap .crep-label {
    display: flex;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    margin-top: 10px;
    padding-left: 17px;
    width: 100%;
    font-family: "UniNeueRegular", sans-serif;
}
#contained-modal-title-vcenter{
    font-size: 20px;
    font-family: "UniNeueRegular", sans-serif;
    line-height: 24px;
    background: #2d32aa;
    text-transform: capitalize;
    color: #fff;
    font-weight: 400;
    padding: 8px 15px 10px 8px;
}
.editIcon
{
    color: #2d32aa;
   
}
.inviteRepresentativeButtonDiv {
    
        flex: 0 0 auto;
        width: 3.3333333%;
        margin-left: 60px;
    
    
   
}

.inviteRepresentativeAccBtnDiv
{
    
        flex: 0 0 auto;
        width: 3.33333333%;
        margin-left: 16px;
        padding-left: 45px !important;
    
}
.inviteRepresentativeAccBtn {
    padding-left: 0px !important;
    /* margin-left: -1px; */
    
}
.inviteRepresentativeButton{
margin-right: -35px !important;
}



