
.api-keys-wrap-current, #apiKeysCurrent
{
    color: #ffffff !important;
    background-color: rgba(45, 50, 170, 1)!important;
    line-height: .8px;
    padding: 13px;
    margin: 0 0 5px 0;
}

.api-keys-wrap-endOffLife{

    color:#797979;
    background-color: '#D8D8D8'!important;
    line-height: .8px;
    padding: 13px;
    margin: 0 0 5px 0;
}
.accordion-button.commonMargin::after {
     margin-left: 0px !important;
    pointer-events: all;
}
.deleteProject .bi-trash{
  
    color: white;
}
/* #saveChangesButton:focus
{
    background-color: #2d32aa !important;
    color: #fff !important;
} */
.highlighted {
    background-color: #2d32aa !important;
    color: #fff !important;
  }
.api-key-color-blue{
    color: #fff;
    background-color: rgba(45, 50, 170, 1)!important;
}

.comm-button-style {
    border-radius: 40px;
    background: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    min-width: 113px;
    padding: 13.5px 33px;
    border: 1px solid #2d32aa;
    color: #2d32aa;
    margin: 0 10px 0 0;
    transition: all .2s ease-in-out;
}

.apim-pop-button-w {
    display: inline-block;
    text-align: right;
    width: 100%;
}
div.row {
    --bs-gutter-x: 0px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));  
}

.api-navigation {
    margin: 28px 0 0 0;
    padding: 0;
   
}
.fixed-position{
    position: sticky;
    top: 10px; /* Adjust as needed to control vertical offset */
    align-self: flex-start; 
    z-index: 10; 
  
}

a{text-decoration: none}
ul{
    list-style-type: none;
    padding-left: 0;
}

.c-blue{
    color: #2d32aa;
}

.bg_prod{
    background-color: #00bef0;
    color: white;
}

.bg_test{
    background-color: #ed8b00;
    color: white;
}
.bg_sandbox{
    background-color: gainsboro;
    color: #2d32aa;
}



.btn.disabled, .btn:disabled, fieldset:disabled .btn  {
    color: var(--bs-btn-disabled-color);
    /* pointer-events: none; */
    background-color: #adb5bd;
    border-color: #adb5bd;
    /* opacity: var(--bs-btn-disabled-opacity); */
    cursor: not-allowed;
    cursor: not-allowed;
}


.gatway-group-name {
    font-size: 15px;
    display: block;
    line-height: 18px;
    font-weight: bold;
}
.gatway-token-name {
    font-size: 13px;
    line-height: 18px;
    margin: 4px 0 0 0;
    display: block;
}
.mpa-project-header {
    font-size: 25px;
    text-transform: uppercase;
    margin: 15px 0 10px 0;
    font-family: UniNeueRegular, sans-serif;
    font-weight: 600;
}
.mpa-project-header-company {
    font-size: 29px;
    line-height: 1;
    margin: 0;
    font-family: UniNeueRegular, sans-serif;
    font-weight: 600;
}


.api-navigation a:hover {
    color: #050875;
}
.project-font-style
{
    font-size: 15px;
    font-family: UniNeueRegular, sans-serif;
}
.project-arrow-style{
    -webkit-text-stroke: 3px;
}

.Toastify__toast-container {
    z-index: 9999;
  }
  
  .Toastify__toast {
    font-size: 16px;
  }
  
  .accordion .credentialAccordBtn:disabled{
    
    --bs-accordion-btn-focus-box-shadow: none;
    /* --bs-accordion-btn-color: white !important; */
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23999%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27 stroke=%27%23999%27 stroke-width=%271%27/%3e%3c/svg%3e");
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23999'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%23999' stroke-width='1'/%3e%3c/svg%3e");

  }
  /* .Toastify__toast--success {
    background-color: #4caf50;
  }
  
  .Toastify__toast--error {
    background-color: #f44336;
  } */
  
  /* .Toastify__progress-bar {
    background: #ffffff;
  } */

  /* .accordion{
    
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-btn-color: #2d32aa !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232d32aa'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%232d32aa' stroke-width='1'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232d32aa'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%232d32aa' stroke-width='1'/%3e%3c/svg%3e");
  } */

  .accordion .api-keys-wrap-current-white {
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-btn-color: white !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%23fff' stroke-width='1'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' stroke='%23fff' stroke-width='1'/%3e%3c/svg%3e");
}

.noSelectedApiText {
    font-weight: bold;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0px;
    /* margin: 18px 98px 0 0; */
    display: inline-block;
    color: #2d32aa;
    font-family: "Noteworthy Light", cursive !important; /* A classic choice */
    font-style: italic;
    margin-left: 37%;
    margin-bottom: 5%;
    margin-top: 3%;
   
}
.noCreatedAPICredentialText{
    font-weight: bold;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0px;
    /* margin: 18px 98px 0 0; */
    display: inline-block;
    color: #2d32aa;
    font-family: "Noteworthy Light", cursive !important;
    font-style: italic;
    margin-left: 10%;
    margin-bottom: 5%;
    margin-top: 3%;
}
.noAddedMemberText{
    font-weight: bold;
    font-size: 18px;
    line-height: 35px;
    letter-spacing: 0px;
    /* margin: 18px 98px 0 0; */
    display: inline-block;
    color: #2d32aa;
    font-family: "Noteworthy Light", cursive !important;
    font-style: italic;
    margin-left: 70%;
    margin-bottom: 5%;
    margin-top: 3%;
}
.createdAPICredentialText{
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0px;
    /* margin: 18px 98px 0 0; */
    display: inline-block;
    color: #2d32aa;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
   
   
}
.createdAPICredentialTextProd{
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 0px;
    margin-top: 0px !important;
    /* margin: 18px 98px 0 0; */
    display: inline-block;
    color: #2d32aa;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif
   
   
}
.bi-arrow-up-short
{
   color: #2d32aa;
   font-size: xx-large;
  
}
.NoPropText{
    color: #2d32aa;
    margin-top: 1% !important;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 480;
}

.arrowContainer {
    display: flex;
    align-items: center;  /* Vertically center content */
    justify-content: flex-start;  /* Align content to the left */
    position: relative; /* Ensures that any absolutely positioned elements inside this container stay within it */
  }
  
  
  
  svg {
    flex-shrink: 0; /* Prevents the arrow from shrinking or growing unexpectedly */
  }
  
  .rotate-90 {
  margin-left: 90%;
  display: inline-block;
  font-size: 38px; /* Adjust size as needed */
  transform-origin: center;
  transform: 
    rotate(30deg)            /* Rotate to align the shape */
    scaleX(2.5)              /* Scale horizontally to elongate */
    scaleY(3.5)              /* Scale vertically to stretch */
    skewX(0deg)            /* Skew horizontally to create a twist */
    skewY(-3deg);
  transition: transform 0.3s ease;
  }
  
 
  
