.my-modal{
    width: 700px;

}
.apim-pop-header {
    font-size: 20px;
    line-height: 24px;
    background: #2d32aa;
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    padding: 13px 20px 13px 20px;
}

.custom-input-wrapp{
    color: #2d32aa;
    font-weight: bold;
}
.pop-close-wrap {
    float: right;
    font-size: 16px;
   
    padding: 4px 0 4px 5px;
    color: #fff;
    text-decoration: none;
}
.comm-button-style.secondary:hover , .comm-button-style.primary:hover{
    background-color: #212861;
    color: white;
}