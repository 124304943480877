div.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
}

.item {
  text-decoration: none;
  font-size: 20px;

  color: #000;

}

.user-aplha {
  background: #fff;
  width: 160px;
  height: 160px;
  display: inline-block;
  border: 1px solid #2d32aa;
  border-radius: 50%;
  text-align: center;
  font-size: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mp-user-info-w {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.mp-user-info {
  margin: 0 0 0 30px;
  color: #000;
  font-size: 30px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}




.comm-button-style {
  border-radius: 40px;
  background: #fff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
  min-width: 113px;
  padding: 13.5px 33px;
  border: 1px solid #2d32aa;
  color: #2d32aa;
  margin: 0 10px 0 0;
  transition: all .2s ease-in-out;
}

.delete {
  border: 1px solid red;
  color: red;
  transition: all .15s ease-in-out;
}

.container {
  color: #2d32aa;
}

h3 {
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  margin: 0 0 12px 0;
}

p {
  margin: 0 0 36px 0;
}


.primary {
  background: #2d32aa;
  color: #fff;
}

.disabledbtn {
  background: #c9cac8;
  color: #6c757d;
  border: 1px solid #c9cac8;
}