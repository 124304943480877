.view-c-wrap.view-copy-credentials {
    margin: 0 0 10px 0;
    
  }
  .view-c-content {
    font-size: 12px;
    display: flex;
}
.view-c-wrap.view-copy-credentials>li:first-child .view-c-content li:first-child {
    padding: 0 0 0 10px;
}
.view-c-content li:first-child {
    width: 55%;
    text-transform: none;
}
.view-c-content li.key-txt {
    padding: 0 0 0 13px;
}
.view-c-content li {
    display: inline-block;
    color: #2d32aa;
    font-size: 12px;
    line-height: 18px;
}

.Popover.top> .arrow:after {
    border-top-color: #2d32aa;
}
.view-c-wrap.view-copy-credentials>li:last-child {
    background: #c9e9fb;
    padding: 13px;
}
.view-c-wrap.view-copy-credentials>li {
    margin: 0 0 15px 0;
}
.view-c-wrap>li {
    margin: 0 0 10px 0;
}
.viewCredPopUp{
    height: 70%;
  }
  .viewCredModal{
    width: 70%;
  }
/* .a,.row{
    pointer-events: none;
} */
.bi-clipboard::before {
    content: "\f290";
}
/* .viewCredBody{
    overflow: -moz-hidden-unscrollable;
} */
.comm-button-style.primary{
    background: #2d32aa;
    color: #fff;
}
.comm-button-style.secondary{
    color: #2d32aa;
    background: #fff;
}
comm-button-style {
    border-radius: 40px;
    background: #fff;
    text-transform: uppercase;
    font-size: 8px;
    line-height: 10px;
    min-width: 113px;
    padding: 13.5px 33px;
    border: 1px solid #2d32aa;
    color: #2d32aa;
    margin: 0 10px 0 0;
    transition: all .2s ease-in-out;
}
.comm-button-style.secondary:hover , .comm-button-style.primary:hover{
    background-color: #212861;
    color: white;
}


/* hide scrollbar for chrome, safari and opera */
/* .container::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
} */