/* .mp-pass-cond.pass-check {
    position: absolute;
    left: 105%;
    top: -38px;
    width: 281px;

    h3 {
      font-weight: normal;
    }

    &.display-none {
      display: none;
    }

    &.display-block {
      display: block;
    }
  } */
  .change-password-mpc .mp-pass-cond.pass-check {
    position: absolute;
    left: 105%;
    top: -38px;
    width: 281px;
}
.cp-myp-left-sec {
  width: 50%;
  margin: 0 5% 0 5%;
     
}
.pass-check {
  margin: 45px 30px 10px 0;
}
  .change-password-mpc {
    position: relative;
    width: 80%;

    .pass-elements {
      li {
        margin: 0 0 25px 0;

        &:nth-child(2) {
          margin: 0 0 44px 0;
        }
      }
    }

 
  }
  .change-phone-number-mpc{
    position: relative;
    width: 80%;
  }
  .change-display-name-mpc {
    .pass-elements {
      li:first-child {
        margin: 0 0 26px 0;
      }
    }
  }
  .countryCode-label
  {
      color: #524d4d;
      font-size: 12px;
  }

  .react-tel-input .country-list .search-box {
    border: 1px solid #cacaca;
    border-radius: 3px;
    font-size: 13px;
    line-height: 13px;
    margin-left: 6px;
    padding: 3px 8px 5px;
    outline: none;
}


.react-tel-input .country-list .country {
  font-family: UniNeueRegular, sans-serif !important;
  font-size: 13px !important;
  line-height: 13px !important;
  padding: 4px 6px;
}

.comm-button-style {
  margin: 0 0px 0 10px;
}