
.Modal-header .Modal-title {
    font-size: 20px;
    line-height: 24px;
    background: rgb(45, 50, 170);
    /* text-transform: capitalize; */
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 13px 20px;
}
.my-modal{
    width: 700px;

}
.inputText {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 18px;
    line-height: 24px;
    border-bottom: none;
    width: 100%;
    color: #2d32aa;
    padding: 0;
    font-display: block;
    font-weight: bold;
}
.selectApiText{
    color: #2d32aa !important;
    
}
.customDisplayMessageRemoveApi{
    color: #333333 !important;
    font-size: 13.1px;
    margin-top: 30px;
    font-family: "UniNeueRegular", sans-serif;
    font-weight: 60;
}

.input-info-label {
    color: #A2A2A2;
    margin-left: 12px;
  }
  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 1px;
    transition: 0.2s ease all;
    font-size: 20px;
    line-height: 20px;
    color:yellow;
    font-weight: bold;
  }
.apim-pop-header {
    font-size: 20px;
    line-height: 24px;
    background: #2d32aa;
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    padding: 13px 20px 13px 20px;
}
.pop-close-wrap {
    float: right;
    font-size: 16px;
    margin: 0;
    padding: 4px 0 4px 5px;
    color: #fff;
    text-decoration: none;
}
 /* .btn-close{
    float: right;
    font-size: 16px;
    margin: 0;
    padding: 4px 0 4px 5px;
    color: rgb(255, 255, 255);
    text-decoration: none;
    background-color: none;
} */
.floating-label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 1px;
    transition: .2s ease all;
    font-size: 20px;
    line-height: 24px;
}
.floating-label .custom-input-wrapp{
    color: #2d32aa;
    font-weight: bold;
}
.wrapper span {
    padding-bottom: 10px;
}
.apim-pop-content .custom-input-wrapp {
    position: relative;
}
.modal-content{
height: 700px;
}
.form-error {
    color: red;
    margin: 6.9px 0;
    visibility: hidden;
    padding: 0;
    clear: both;
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
}
/* #createButton {
    background: #c9cac8;
    border: 1px solid #c9cac8;
    font-weight: normal;
    cursor: not-allowed;
} */
.form-control {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 24px;
    line-height: 19px;
    border-bottom: 1px solid #707070;
    width: 100%;
    color: #2d32aa;;
    padding: 7px 0 10px 0;
    font-weight: bold;
}
/* .custom-input-wrapp
{
    height: fit-content;
} */
.input-group{
    margin: 54px 0 53px 0;
}
.comm-button-style.secondary:hover , .comm-button-style.primary:hover{
    background-color: #212861;
    color: white;
}
#addNewMemberButton:disabled,#closeButton:disabled {
    background: #c9cac8;
    border: 1px solid #c9cac8;
    font-weight: normal;
    cursor: not-allowed;
}