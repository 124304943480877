.pass-check {
    display: flex;
    margin: 45px 0 0 0;
    background-color:#e7f1ff;
    margin-left:20px;
    margin-bottom: 20px;

    h3 {
      font-size: 15px;
      font-weight: bold;
    }

    ul {
      margin: 0 0 0 20px;
      

      li {
        line-height: 1.4;
        margin: 0 0 0 0;
        padding: 0 0 0 10px;
        position: relative;

      }
    }
  }
  .comm-button-style.secondary:hover , .comm-button-style.primary:hover{
    background-color: #212861;
    color: white;
}
  .change-pass-wrap li {
    margin: 10px 0 20px 0;
}
  .change-pass-wrap {
    display: flex;
    max-width: 1000px;
    margin: auto;

    .pass-elements {
      margin-top: 55px;

      li {
        margin: 0 0 30px 0;
      }
    }
    
    .required-field-op {
        float: right;
        font-size: 11px;
        line-height: 13px;
        color: #A2A2A2;
        margin: 7px 0 0 0;
        padding: 0;
      }

    .cp-left-sec {
      width: 40%;
      margin: 0 10% 0 0;
         
    }

    .pass-elements {
      width: 50%;
    }
    .my-modal {
      width: 80vw  ;  /* Occupy the 90% of the screen width */
      max-width: 90vw;
  }
  }
