

/* to convert dropdown button to cross button */

/* Hide the default arrow of the select */
.dropdown-default {
    background: url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
  }
  
  /* Hide the default arrow when an option is selected */
  .dropdown-with-close {
    background: none;
  }
  
  /* Style the close icon */
  .clear-icon-serviceRqstSubType {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .clear-icon-serviceRqstSubType:hover {
    color: #2d32aa;
  }
  
  
  
  